<template>
  <!-- Error page-->
  <div class="misc-wrapper bg-light">
    <b-link class="brand-logo">
      <vuexy-logo />
    </b-link>

    <div class="misc-inner p-2 p-sm-3 bg-light w-100 h-100">
      <div class="w-100 text-center">
        <h2 class="mb-1 h1">Thông báo</h2>
        <p class="mb-2 h4">
          Dịch vụ mua sim số của iTel đang nâng cấp,<br />
          Bạn quay lại sau với iTel nhé
        </p>

        <!-- <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{path:'/'}"
        >
          Back to home
        </b-button> -->

        <!-- image -->
        <b-img fluid class="w-100" :src="imgUrl" alt="Error page" />
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import store from "@/store/index";

export default {
  components: {
    VuexyLogo,
    BLink,
    BButton,
    BImg,
  },
  async mounted() {
    window.addEventListener("message", this.handleMessageEventer, false);
    if (this.$route.query.from == "appmyitel") {
      this.hiddenHeaderAndFooter();
      if (window.hideLayout) {
        window.hideLayout();
      }
    }
  },
  methods: {
    hiddenHeaderAndFooter() {
      const headH = document.querySelector(".fs-header");
      if (headH) {
        headH.style.display = "none";
      }
      const footer = document.querySelector(".fs-footer");
      if (footer) {
        footer.style.display = "none";
      }
      let css = "#cs-live-chat { display: none !important; }",
        head = document.head || document.getElementsByTagName("head")[0],
        style = document.createElement("style");
      style.type = "text/css";
      head.appendChild(style);

      if (style.styleSheet) {
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
    },
  },
  data() {
    return {
      downImg: require("@/assets/images/pages/error.svg"),
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require("@/assets/images/pages/error-dark.svg");
        return this.downImg;
      }
      return this.downImg;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
<style>
.brand-logo svg {
  height: 38px !important;
  width: auto !important;
}
</style>
